import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

import App from "./App";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import "./fonts/Adia_Demo.ttf";

import "./index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
      {/* <Routes>
        <Route path="/" element={<App />} />
        <Route path="intro" element={<Intro />} />
        <Route path="onboard" element={<Onboard />} />
        <Route path="progress" element={<Progress />} />
        <Route path="assessment" element={<Assessment />} />
        <Route path="complete" element={<Complete />} />
        <Route path="noassessment" element={<NoAssessment />} />
      </Routes> */}
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
