import { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap";

import { withRouter } from "../utils/withRouter.js";

import "./AlpacaProfileBubble.css";

// AlpacaProfileBubble class created
class AlpacaProfileBubble extends Component {
  // constructor method used to initialize an objects state
  // is automatically called during the creation of an object
  // super is required to be in a constructor for it to work
  constructor() {
    // short for superclass constructor
    super();
  }

  componentDidMount() {}

  componentDidUpdate() {}

  // render used to display html code in index.html
  render() {
    // bubbles object containing links out to imgs folder with small and large bubble sizes
    const bubbles = {
      small: `${process.env.PUBLIC_URL}/assets/imgs/Speech-Balloon-small-02.png`,
      large: `${process.env.PUBLIC_URL}/assets/imgs/Speech-bubble-01.svg`,
    };

    // alpaca object containing links out to alpaca side profile, head and headphones img
    const alpaca = {
      // front: 'assets/imgs/Alpaca-front-01.svg',
      front: `${process.env.PUBLIC_URL}/assets/imgs/Alpaca-front-01.svg`,
      profile: `${process.env.PUBLIC_URL}/assets/imgs/Alpaca-side-01.svg`,
      head: `${process.env.PUBLIC_URL}/assets/imgs/Alpaca.svg`,
      headphones: `${process.env.PUBLIC_URL}/assets/imgs/Alpaca-headphones.svg`,
      sunglasses: `${process.env.PUBLIC_URL}/assets/imgs/Alpaca-sunglasses-speech.png`,
      audio_img: `${process.env.PUBLIC_URL}/assets/imgs/Alpaca-front-audio.svg`,
      alpaca_medal: `${process.env.PUBLIC_URL}/assets/imgs/Alpaca-medal.svg`,
    };

    return (
      // fragment allows for code to be rendered without use of divs
      <Fragment>
        <Row id="alpaca_profile">
          <Col xs={5} className="alpaca_container">
            <img src={alpaca[this.props.alpaca]} className="alpaca-img" />
          </Col>
          <Col xs={7}>
            <div className="bubble_container">
              <img src={bubbles[this.props.bubble]} className="bubble" />
              <div className="speechBubbleContent-Large">
                {this.props.content}
              </div>
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

// get access to history objects props and closest route match
// will pass updated match, loaction and history props to the wrapped component when it renders
export default withRouter(AlpacaProfileBubble);
