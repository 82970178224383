import { Component, Fragment } from "react";

import { withRouter } from "../utils/withRouter.js";

import "./NavigationButton.css";

class NavigationButton extends Component {
  constructor() {
    super();
  }

  handleClick() {
    // this.props.router.navigate( this.props.target );
    // a bit hacky but can review later
    // below is done to allow for bgmusic in onboarding to stop
    // on button click
    if (this.props.target === "/") {
      this.props.setMmIsPlaying(false);
      setTimeout(() => {
        this.props.router.navigate(this.props.target);
      }, 100);
    } else {
      this.props.router.navigate(this.props.target);
    }

    // added in to force navigation and page load 
    // when error page hits
    if(this.props.loading){
      this.props.router.navigate(this.props.target);
      window.location.reload();
    }
  }

  render() {
    return (
      <Fragment>
        <button
          type="button"
          className="btn btn-circle btn-xl"
          onClick={this.handleClick.bind(this)}
        >
          <i className="bi-play-circle"></i>
        </button>
      </Fragment>
    );
  }
}

export default withRouter(NavigationButton);
