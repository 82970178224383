import { Fragment, useEffect, useRef } from "react";
import { Howl } from "howler";

function BGMusicPlayer({
  bgAudioFile,
  bgOnEndedCallback,
  bgIsPlaying = false,
  setBgIsPlaying,
}) {
  const bgAudioPlayerRef = useRef();

  const bgOnErrorCallback = (attempt) => {
    // Maximum number of retry attempts
    const maxAttempts = 3;

    // console.log(
    //   `Error playing background audio (Attempt ${attempt}/${maxAttempts}). Retrying...`
    // );

    // Check if maximum retry attempts reached
    if (attempt >= maxAttempts) {
      // console.log(
      //   "Max retry attempts reached. Unable to play background audio."
      // );
      return;
    }

    // Retry playing audio after a short delay
    setTimeout(() => {
      setBgIsPlaying(true); // Attempt to play again
    }, 1000); // Adjust the delay as needed

    // Increment attempt counter
    attempt++;
  };

  useEffect(() => {
    const bgAudioPlayer = new Howl({
      src: bgAudioFile,
      loop: true,
      preload: true,
      onend: bgOnEndedCallback,
      onplayerror: (id, error) => {
        // console.log(
        //   `[BGAudioPlayer] error - Attempting to play file '${bgAudioFile}'`,
        //   error
        // );
        bgOnErrorCallback();
      },
    });

    // Store the Howl instance in the ref
    bgAudioPlayerRef.current = bgAudioPlayer;

    return () => {
      // Ensure that the Howl instance is properly unloaded
      bgAudioPlayer.unload();
    };
  }, [bgAudioFile]);

  useEffect(() => {
    if (bgIsPlaying) {
      bgAudioPlayerRef.current.seek(0);
      bgAudioPlayerRef.current.play();
    } else {
      bgAudioPlayerRef.current.pause();
    }
  }, [bgIsPlaying]);

  return <Fragment></Fragment>;
}

export default BGMusicPlayer;
