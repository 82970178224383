import { Component, Fragment, useState } from "react";
import Logout from "../components/Logout";
import PauseButton from "./PauseButton";
import PauseModal from "./PauseModal";

import React from "react";

import { withRouter } from "../utils/withRouter.js";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "./HeadingBanner.css";

function HeadingBanner(props) {
  // const [teacherName, setTeacherName] = useState(null)

  // useEffect(() => {
  //     whoami()
  //     .then((response)=>{
  //         setTeacherName(response.data.stakeholder.name)
  //         })
  //   }, []);
  // let setMmAudioIsPlaying = props.setMmAudioIsPlaying;
  // let setBgAudioIsPlaying = props.setBgAudioIsPlaying;
  // let bgAudioIsPlaying = props.bgAudioIsPlaying;
  let extraScreensShowing = props.extraScreensShowing;
  let isPracticeQuestion = props.isPracticeQuestion;
  let setIsTimerPaused = props.setIsTimerPaused;
  let resumeTimeout = props.resumeTimeout;
  let survey = props.survey;
  let toLastPage = props.toLastPage;
  let handlePauseAudio = props.handlePauseAudio;
  let showPause = props.showPause;
  let setShowPause = props.setShowPause;

  const pageName = survey?.currentPage?.questions[0].name;
  const practiceQuestion = survey?.currentPage?.questions[0].practice;

  let replayHandler = props.replayHandler;

  const colour = useState("grey");

  const headerStyles = "header " + colour;

  const cloudContent = (
    <div>
      <div className="leftPosition"></div>
      <div className="centrePosition">{props.centreButton}</div>
      <div className="rightPosition">
        {/* <Logout isLoggedIn={props.isLoggedIn} /> */}
        {/* <PauseButton /> */}
        {props.isLoggedIn &&
        isPracticeQuestion !== true &&
        extraScreensShowing !== true ? (
          <PauseModal
            showPause={showPause}
            setShowPause={setShowPause}
            handlePauseAudio={handlePauseAudio}
            replayHandler={replayHandler}
            survey={survey}
            toLastPage={toLastPage}
            resumeTimeout={resumeTimeout}
            setIsTimerPaused={setIsTimerPaused}
          />
        ) : null}
      </div>
      <div className="rightPosition"></div>
    </div>
  );

  return (
    <div className="headerContainer">
      <ColourHeader
        colour={props.colour}
        text={props.headingText} /*isLoggedIn={props.isLoggedIn}*/
      />
      <MainBanner
        text={props.text}
        cloud={props.cloud}
        control={cloudContent}
      />
    </div>
  );
}

/**
 * Simple banner across the top of the screen.
 * Colour can be customised depending on the parameterisation to one of the predefined values
 *
 * @param {} colour
 * @param {} text
 * @param {} isloggedIn
 * @returns
 */
function ColourHeader({ colour = "grey", text = "" }) {
  return (
    <Row className={`header ${colour}`}>
      <Col className="col-2"></Col>
      <Col className="d-flex justify-content-center">
        <h1 className="mainHeaderText">{text}</h1>
      </Col>
      <Col className="col-2"></Col>
    </Row>
  );
}

// get the current date in DD/MM/YYYY format
function getCurrentDate() {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const year = today.getFullYear();

  return `${day}/${month}/${year}`;
}

function MainBanner({
  text = `Assessing Letter & Phonemic Awareness Class Assistant - (${getCurrentDate()})`,
  cloud = false,
  control = null,
}) {
  return (
    <Row className="subheader">
      <Col className="d-flex">
        <div className="logo col-2"></div>
        <div className="col-7">
          <h3 className="subheading-text">{text}</h3>
        </div>
        <div className={`${cloud ? "cloud" : "circle"}`}>{control}</div>
      </Col>
    </Row>
  );
}

export default React.memo(withRouter(HeadingBanner));
