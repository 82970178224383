// SlowInternetModal.js

import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./UpdateModal.css";

const UpdateModal = ({ show, handleClose, onUpdate, version }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="noInternet"
      style={{ zIndex: 9999 }}
    >
      <Modal.Body>
        <div className="image-container">
          <img
            className="internet-bubble-img"
            src={process.env.PUBLIC_URL + "/assets/imgs/internetBubble.svg"}
            alt="Internet Bubble"
          />
          <div className="overlay-update">
            <div className="updateContainer">
              <img
                className="updateImage"
                src={process.env.PUBLIC_URL + "/assets/imgs/bell-ringing.svg"}
              ></img>
            </div>
            <div className="text">
              <h2>New Version Available</h2>
              <p>
                A new version of the App<br></br>
                {version} is available.<br></br>Please update to get the latest
                features and<br></br>improvements.
              </p>
            </div>
            <div>
              <Button variant="primary" onClick={onUpdate} className="update">
                Update
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateModal;
