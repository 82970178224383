import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

function PauseButton(props) {
  let survey = props.survey;
  let navigate = useNavigate();

  // function handlePause(survey) {
  //   navigate("/pause", {
  //     state: {
  //       survey: { survey },
  //     },
  //   });
  // }

  function handlePause() {
    navigate("/pause");
  }

  return (
    <Fragment>
      <button id="pause-button" onClick={handlePause}>
        <img src="assets/imgs/pause-button.svg" className="logout-button"></img>
      </button>
    </Fragment>
  );
}

export default PauseButton;
