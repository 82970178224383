import { Fragment } from "react";

import {withRouter} from '../utils/withRouter.js';

import { useNavigate } from "react-router-dom";

import './Logout.css';

function Logout(props) {

    let navigate = useNavigate();

    async function handleLogout(event) {
        event.preventDefault();

        // There should only be one key/value pair in localStorage 
        // so we can be lazy and just call clear()
        localStorage.clear();

        navigate("/");
    }

    if( !props.isLoggedIn ) {
        return null;
    }

    return(
        <Fragment><a href="#" onClick={handleLogout}><img src="assets/imgs/logout_icon.svg" alt="logout" className="logout-button"/></a></Fragment>
    )
}

export default withRouter(Logout);