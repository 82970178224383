import React, { useState, useEffect, Component } from "react";
import HeadingBanner from "./HeadingBanner";
import NavigationButton from "./NavigationButton.js";
import AlpacaProfileBubble from "./AlpacaProfileBubble";

import { useNavigate } from "react-router-dom";
import { withRouter } from "../utils/withRouter.js";

import "./ErrorBoundary.css";
import { logErrors } from "../services/APIClient.js";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo,
    });
    // const errorLog = {
    //   studentId: localStorage.getItem("studentId"),
    //   classId: localStorage.getItem("classId"),
    //   url: window.location.href,
    //   errorMessage: JSON.stringify({
    //     message: error.toString(),
    //     stack: errorInfo.toString(),
    //   }),
    //   deviceType: localStorage.getItem("deviceType"),
    // };
    // logErrors(errorLog);
  }

  componentDidMount() {
    // Add the event listener here
    window.addEventListener("error", this.handleError);
  }

  handleError = (event) => {
    // // console.log(typeof event.error, event);
    // const { message, stack } = event.error;
    // const errorLog = {
    //   studentId: localStorage.getItem("studentId"),
    //   classId: localStorage.getItem("classId"),
    //   url: window.location.href,
    //   errorMessage: JSON.stringify({
    //     message: message.toString(),
    //     stack: stack.toString(),
    //   }),
    //   deviceType: localStorage.getItem("deviceType"),
    // };
    // logErrors(errorLog);
  };

  componentWillUnmount() {
    // Remove the event listener to prevent memory leaks
    window.removeEventListener("error", this.handleError);
  }

  pageReload = () => {
    this.props.router.navigate("/progress");
    window.location.reload();
  };

  render() {
    const { hasError, error, errorInfo } = this.state;
    const { fallbackComponent: FallbackComponent, children } = this.props;
    if (hasError) {
      return (
        <>
          <HeadingBanner
            cloud={true}
            isLoggedIn={false}
            headingText="Error Page!"
          />
          <AlpacaProfileBubble
            content={
              <div>
                <h2>Oh no! Something went wrong.</h2>
                <h3>Press the Green button to fix.</h3>
                <div className="complete-button">
                  <NavigationButton target="/progress" loading={true} />
                </div>
              </div>
            }
            alpaca="audio_img"
            bubble="large"
          />
        </>
      );
    }

    return children;
  }
}

export default ErrorBoundary;

// function ErrorBoundary({ children }) {
//   const [hasError, setHasError] = useState(false);

//   const pageReload = () => {
//       window.location.reload();
//   }

//   const handleError = (error) => {
//     console.error(error);
//     setHasError(true);
//     // You can also log the error to an error tracking service here
//   };

//   try {
//     // Wrap the children in a try-catch block
//     return (
//       <React.Fragment>
//         {children}
//       </React.Fragment>
//     );
//   } catch (error) {
//     handleError(error);
//   }

//   if (hasError) {
//     // Render the error fallback UI
//     return (
//       <>
//       <HeadingBanner cloud={true} isLoggedIn={false} headingText="Error Page!" />
//       <div className='center'>
//         <div className="error-img-container">
//           <h1>Oh no! Something went wrong. Press the Green button to fix</h1>
//           <img src="assets/imgs/resume-button.png" alt="PageReload" className="page-reload" onClick={pageReload} />
//         </div>
//       </div>
//     </>
//     );
//   }

//   // Render the children if there's no error
//   return children;
// }

// export default ErrorBoundary;

// function ErrorBoundary({ children }) {
//   const [hasError, setHasError] = useState(false);
//   const [error, setError] = useState(null);
//   const [errorInfo, setErrorInfo] = useState(null);

//   const pageReload = () => {
//     window.location.reload();
//   }

//   useEffect(() => {
//     const errorHandler = (error, errorInfo) => {
//       console.error(error, errorInfo);
//       setHasError(true);
//       setError(error);
//       setErrorInfo(errorInfo);
//       // You can also log the error to an error tracking service here
//     };

//     window.addEventListener('error', errorHandler);

//     return () => {
//       window.removeEventListener('error', errorHandler);
//     };
//   }, []);

//   const handleError = () => {
//     // console.log("handleError function called");
//     setHasError((prevHasError) => {
//       // console.log(prevHasError); // Log the previous value of hasError
//       return true; // Update hasError to true
//     });
//   };

//   useEffect(() => {
//     // console.log(hasError);
//   }, [hasError]);

//   if (hasError) {
//     return (
//       <>
//         <HeadingBanner cloud={true} isLoggedIn={false} headingText="Error Page!" />
//         <div className='center'>
//           <div className="error-img-container">
//             <h1>Oh no! Something went wrong. Press the Green button to fix</h1>
//             <img src="assets/imgs/resume-button.png" alt="PageReload" className="page-reload" onClick={pageReload} />
//           </div>
//         </div>
//       </>
//     );
//   }

//   return children;
// }

// export default ErrorBoundary;
